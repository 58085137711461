import React from 'react'
import BaseComponent, { errorMap } from './BaseComponent'
import Dropzone from 'react-dropzone'

export default class SingleImageUpload extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { files: [], imageError: false, preloader: false, preview: "" }
    this._bind('onDrop', 'removeFiles', 'remove', 'save', 'getFileData')
    this.getFileData(this.props.file)
  }

  onDrop(acceptedFiles, rejectedFiles) {
    if(acceptedFiles.length > 0) {
        this.setState({
            imageError: false,
            files: acceptedFiles
        })
        this.save(acceptedFiles)
    } else if(rejectedFiles.length > 0) {
        console.log("rejectedFiles")
        this.setState({
            imageError: true
        })
    }
  }

  removeFiles() {
    this.setState({
      files: []
    })
    if(this.props.file && this.props.file.uuid) this.remove(this.props.file.uuid)
  }

  remove(uuid) {
    this.setState({ preloader: true })
    let url = this.props.API+"/edit/trash/recursive/file/"+uuid
    fetch(url, {
      method: "POST",
      headers: {
          "Authorization": "Bearer " + sessionStorage.getItem('token')
      }
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (!result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error?.ident)}}), true);
        this.setState({ preloader: false })
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Usunięto plik. Pamiętaj aby zapisać zmiany!"}}), true);
        this.setState({ preloader: false })
        this.props.onChange(null)
      }
    }, (error) => {
      //console.log(error)
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Coś poszło nie tak."}}), true);
      this.setState({ preloader: false })
    })
  }

  save(files) {
    this.setState({ preloader: true })
    let formData = new FormData()
    for (var f = 0; f < files.length; f++) {
        formData.append('files[0]', files[f], files[f].name);
    }

    let params = {
      file: {
        restricted: false
      }
    }

    formData.append('_input', JSON.stringify(params));
    let data = formData;

    let url = this.props.API+"/file/upload"
    fetch(url, {
      method: "POST",
      headers: {
          "Authorization": "Bearer " + sessionStorage.getItem('token')
      },
      body: data
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (!result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error?.ident)}}), true);
        this.setState({ preloader: false })
      } else {
        // window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Dodano plik. Pamiętaj aby zapisać zmiany!"}}), true);
        this.setState({ preloader: false })
        // this.getFileData(result.data.files[0].uuid)
        if(result.data[0]) result.data[0].name = this.state.files[0]?.name
        this.props.onChange(result.data[0])
      }
    }, (error) => {
      //console.log(error)
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Coś poszło nie tak."}}), true);
      this.setState({ preloader: false })
    })
  }

  getFileData(uuid) {
    if(uuid && (this.props.ident === "image" || this.props.ident === "thumbnail")) {
      let url = this.props.API+"/file/" + uuid

      fetch(url, {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + sessionStorage.getItem('token')
        }
      })
      .then((response) => {
        return response.blob()
      }).then(imageBlob => {
        const imageObjectURL = URL.createObjectURL(imageBlob);
        this.setState({ preview: imageObjectURL })
      });
    }
  }

  render () {
    let fileName = this.state.files.length > 0 ? this.state.files[0].name : this.props.file || ""

    return (
      <div className="dropzone-holder">
        <Dropzone maxSize={20000000} accept={ this.props.ident === "video" ? ["video/mp4"] : ["application/pdf","application/zip"]} className={ this.state.imageError ? "dropzone dropzone-reject" : "dropzone" } activeClassName="dropzone-active" rejectClassName="dropzone-reject" multiple={ false } onDrop={ this.onDrop }>
        {({getRootProps, getInputProps}) => (
            <div className={ this.state.imageError ? "dropzone dropzone-reject" : "dropzone" }>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                { fileName ? <p>Nazwa pliku: { fileName }</p> : <p>Przeciągnij i upuść plik, lub kliknij aby wybrać.</p> }
              </div>
            </div>
          )}
        </Dropzone>
        { this.props.file && this.props.file.uuid ? <div className="remove-file" onClick={ () => { this.removeFiles() } }>Usuń</div> : null }
        { this.state.preloader ? <div className="preloader"><span></span></div> : null }
      </div>
    )
  }
}
