import React from 'react'
import BaseComponent from './BaseComponent'
import Dropzone from 'react-dropzone'
import { errorMap } from './BaseComponent'

export default class SingleImageUpload extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { files: [], imageError: false, preloader: false, preview: "" }
    this._bind('onDrop', 'removeFiles', 'save')
  }

  onDrop(acceptedFiles, rejectedFiles) {
    if(acceptedFiles.length > 0) {
        this.setState({
            imageError: false,
            files: acceptedFiles
        })
        this.save(acceptedFiles)
    } else if(rejectedFiles.length > 0) {
        console.log("rejectedFiles")
        this.setState({
            imageError: true
        })
    }
  }

  removeFiles() {
    this.setState({
      files: []
    })
    if(this.props.image && this.props.image.uuid) this.remove(this.props.image.uuid)
  }

  save(files) {
    this.setState({ preloader: true })
    let formData = new FormData()
    for (var f = 0; f < files.length; f++) {
        formData.append('files[0]', files[f], files[f].name);
    }

    let params = {
      file: {
        restricted: this.props.restricted
      }
    }

    formData.append('_input', JSON.stringify(params));
    let data = formData;

    let url = this.props.API+"/file/upload"
    fetch(url, {
      method: "POST",
      headers: {
          "Authorization": "Bearer " + sessionStorage.getItem('token')
      },
      body: data
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (!result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error?.ident)}}), true);
        this.setState({ preloader: false })
      } else {
        // window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Dodano plik. Pamiętaj aby zapisać zmiany!"}}), true);
        this.setState({ preloader: false })
        // this.getFileData(result.data.files[0].uuid)
        this.props.onChange(result.data[0], files[0].name)
      }
    }, (error) => {
      //console.log(error)
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Coś poszło nie tak."}}), true);
      this.setState({ preloader: false })
    })
  }

  render () {
    let image = this.state.files.length > 0 ? this.state.files[0].name : this.props.file || ""

    return (
      <div className="dropzone-holder">
        <Dropzone maxSize={10000000} accept={["application/pdf"]} className="dropzone" activeClassName="dropzone-active" rejectClassName="dropzone-reject" multiple={ false } onDrop={ this.onDrop }>
        {({getRootProps, getInputProps}) => (
            <div className={ this.state.imageError ? "dropzone dropzone-reject" : "dropzone" }>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                { image ? <p>Nazwa pliku: { image }</p> : <p>Przeciągnij i upuść plik, lub kliknij aby wybrać.</p> }
              </div>
            </div>
          )}
        </Dropzone>
        { this.state.preloader ? <div className="preloader"><span></span></div> : null }
      </div>
    )
  }
}
